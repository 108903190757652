import { useTheme } from "@emotion/react";
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import CustomButton from "../../../components/buttons/CustomButton";

import { TextAreaInput, TextInput } from "../../../components/inputs";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ServicesService from "../../../store/apis/ServicesApi";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object({
  libelle: yup.string().required("La libelle est requise"),
  prix: yup
    .number()
    .typeError("Le prix doit être une valeur numérique")
    .required("Le prix est requis"),
});

const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 24,
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 24,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 56,
    fontSize: 24,
    padding: "1px 16px",
    border: state.isFocused ? "1px solid #3EC3EC" : "1px solid #3EC3EC",
    color: "#ddd",
  }),
  input: (base) => ({
    ...base,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const EditServiceForm = ({ dialog, onClose, subServices }) => {
  const [selected, setSelected] = useState();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState({});
  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    setLoading(true);
    let newData = {
      libelle: data.libelle,
      description: data.description,
      price: data.prix,
    };

    ServicesService.editService(subServices.id, newData)
      .then((res) => {
        console.log(res);
        onClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onAdd = (data) => {
    setLoading(true);
    let newData = {
      libelle: data.libelle,
      description: data.description,
      price: data.prix,
    };

    ServicesService.addService(newData)
      .then((res) => {
        console.log(res);
        onClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (subServices) {
      setValue("libelle", subServices.libelle);
      setValue("description", subServices.description);
      setValue("prix", subServices.price);
    }
  }, [subServices]);

  return (
    <Dialog
      open={dialog}
      TransitionComponent={Transition}
      fullScreen={!isMd}
      PaperProps={{ sx: { minWidth: 600, maxWidth: { md: 600, lg: 600 } } }}
      onClose={onClose}
    >
      <Container maxWidth={"md"}>
        <Box mt={3} mb={3}>
          <Typography
            variant={"h2"}
            fontFamily={"Brandon Grotesque"}
            textAlign={"center"}
          >
            {subServices ? 'Modifier service' : 'Ajouter un service'}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            display={"flex"}
            alignItem={"center"}
            justifyContent={"space-between"}
            rowGap={2}
            flexWrap={"wrap"}
          >
            <Box flexBasis={{ xs: "100%", md: "100%" }}>
              <Box mt={0.75}>
                <Typography
                  variant="caption"
                  component={"label"}
                  fontSize={{ xs: 16 }}
                >
                  Libelle
                </Typography>

                <TextInput
                  placeholder={""}
                  register={{ ...register("libelle") }}
                />
                {errors.libelle && (
                  <Typography fontSize={{ xs: 14 }} color="red">
                    {errors.libelle.message}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 100%" }}>
              <Typography variant="caption" component={"label"}>
                Prix du service
              </Typography>
              <TextInput placeholder={""} register={{ ...register("prix") }} />
              {errors.prix && (
                <Typography fontSize={{ xs: 14 }} color="red">
                  {errors.prix.message}
                </Typography>
              )}
            </Box>
            <Box flex={{ xs: "0 1 100%", md: "0 1 100%" }}> </Box>
            <Box>
              <Box>
                <Typography variant="caption" component={"label"}>
                  Description du service
                </Typography>
                <TextAreaInput
                  register={{ ...register("description") }}
                  // disable={isFetching || sent}
                />
              </Box>
            </Box>
          </Box>
        </form>

        <Box
          display={"flex"}
          mb={4}
          mt={4}
          alignItems={"center"}
          justifyContent={"center"}
          gap={2}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <CustomButton type={"red"} handleClick={onClose}>
                Annuler
              </CustomButton>
              <CustomButton
                type={"yellow"}
                textColor={"white"}
                handleClick={handleSubmit(subServices ? onSubmit : onAdd)}
              >
                {subServices ? 'Modifier' : 'Ajouter'}
              </CustomButton>
            </>
          )}
        </Box>
      </Container>
    </Dialog>
  );
};

export default EditServiceForm;
