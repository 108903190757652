import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import logo from "../../../assets/images/logos/light_logo_lg.png";
import { styled, ThemeProvider } from "@mui/system";
import theme from "../../../assets/theme";
import styles from "../../../assets/css/style.js";
import { StyleSheet } from "react-native";
import btnbg from "../../../assets/images/btnbg.png";
import { ValidationButton } from "../../../components/buttons";
import { TextInput } from "react-native-web";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  DeleteOutline,
  Download,
  KeyboardBackspaceOutlined,
} from "@mui/icons-material";
import AddNewServiceForm from "./NewService.js";
import PrestationService from "../../../store/apis/PrestationApi.js";
import { document_type_devis } from "../../../config/keys.js";
import moment from "moment";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";

moment.locale("fr");

const PdfWrap = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 390,
  fontSize: 14,
  lineHeight: "20px",
  color: "#fff",
  maxWidth: 725,
  border: "1px solid #03EBFF",
  backgroundColor: "rgba(0, 0, 0, .69)",
  padding: "20px 30px",
  marginRight: -5,
  marginLeft: -5,

  ".paddingRight40": {
    paddingRight: 40,
  },
  ".infos": {
    maxWidth: "max-content",
    margin: "0 0 0 auto",
  },
  ".fz18": {
    fontSize: 18,
  },
  ".details": {
    margin: "30px 0 20px",

    ".dt1": {
      width: "56%",
    },
    ".dt2": {
      width: "44%",
    },
  },
  ".h2": {
    marginBottom: 10,
  },
  ".prodlist": {
    borderSpacing: 0,

    tr: {
      td: {
        padding: 6,

        "&:not(:nth-of-type(2))": {
          textAlign: "center",
        },
      },
      "&:first-of-type": {
        fontSize: 16,
        lineHeight: "23px",
      },
      "&:not(:first-of-type)": {
        border: "1px solid #03EBFF",
        td: {
          border: "1px solid #03EBFF",
          verticalAlign: "top",
        },
      },
    },
  },
  ".paiment": {
    margin: "0 -30px",
    padding: "10px 30px 30px",
    borderBottom: "1px solid #03EBFF",
  },
  ".signature": {
    padding: "10px 0",
    img: {
      display: "block",
      margin: "auto",
    },
  },
  ".textBfrBtns": {
    display: "flex",
    marginBottom: 20,

    "> div:first-of-type": {
      flex: 1,
      textAlign: "left",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "0 -20px",
      width: "auto",

      "> div:last-child": {
        display: "none",
      },
    },
  },
  "._btssp": {
    display: "flex",

    "&.disabled": {
      opacity: 0.3,
    },
    "> div:first-of-type": {
      flex: 1,
    },
    "> div:last-child": {
      width: 250,
    },
    "._blcsntr": {
      height: "100%",
      display: "flex",
      border: "2px dashed #04ebff",
      marginLeft: 10,
      alignItems: "center",
      justifyContent: "center",
      fontSize: 18,
      lineHeight: "22px",
      fontFamily: theme.typography.fontFamilyBauhaus,
      color: "#04ebff",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "10px -20px 0",
      width: "auto",

      ">div:last-child": {
        width: "100%",
      },
      "._blcsntr": {
        minHeight: 100,
        margin: "13px 0 0 0",
      },
    },
  },
  ".btn": {
    height: 44,
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #03EBFF",

    "&.style1": {
      backgroundImage:
        "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
      textTransform: "uppercase",
      borderWidth: 2,
      marginBottom: 10,
    },
  },
  ".txt": {
    fontSize: 12,
    lineHeight: "17px",
    margin: "20px 0",
  },
  ".ftr": {
    margin: "auto",
    maxWidth: 270,
    fontSize: 9,
    lineHeight: "13px",
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    ".fz18": {
      fontSize: 14,
    },
    ".head": {
      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        img: {
          width: 105,
        },
      },
    },
    ".infos": {
      margin: 0,
      marginTop: "15px",

      td: {
        textAlign: "left",

        "&:last-child": {
          textAlign: "center",
        },
      },
    },
    ".paiment": {
      padding: "10px 10px 10px",

      "> table > tbody > tr": {
        display: "flex",
        flexDirection: "column-reverse",

        "> td": {
          marginBottom: 15,

          "&:last-child": {
            maxWidth: 170,
            width: "100%",
            margin: "0 0 0 auto",
          },
          "table td:first-of-type": {
            whiteSpace: "nowrap",
            verticalAlign: "text-bottom",
          },
          "table td": {
            paddingTop: 4,
          },
        },
      },
    },
    ".details": {
      marginBottom: 5,

      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        "> td": {
          marginBottom: 15,
        },
      },
      ".dt1, .dt2": {
        width: "100%",

        td: {
          width: "50%",
        },
      },
    },
    ".prodlist ": {
      margin: "15px -31px 0",
      width: "calc(100% + 62px)",

      tr: {
        "&:first-of-type": {
          fontSize: 12,
        },
      },
      ".tgl": {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:before": {
          content: "attr(data-label-sm)",
          fontSize: 14,
          fontWeight: "bold",
          fontFamily: theme.typography.fontFamilyBauhaus,
          color: "#000",
        },
        "&:after": {
          content: '""',
          border: "6px solid transparent",
          borderTopColor: theme.palette.primary.main,
          borderBottom: "none",
          marginLeft: 6,
        },
        "> div": {
          position: "absolute",
          top: "100%",
          left: 0,
          width: 200,
          backgroundColor: "#fff",
          padding: 4,
          zIndex: 100,
          border: "1px solid #ccc",
          borderRadius: 4,
          opacity: 0,
          visibility: "hidden",
        },
        "&:hover": {
          "> div": {
            opacity: 1,
            visibility: "visible",
          },
        },
      },
    },
    ".signature": {
      margin: "0 -20px",
      width: "auto",

      "> tbody > tr": {
        display: "flex",
        flexWrap: "wrap",

        td: {
          width: "50%",
          textAlign: "center",

          "&:first-of-type": {
            width: "100%",
            marginBottom: 15,
            textAlign: "left",
          },
        },
      },
    },
    ".txt": {
      margin: "20px -20px",
    },
  },
}));
const Table = styled("table")(({ theme }) => ({
  width: "100%",
  ".b": {
    fontWeight: "bold",
  },
  ".ffbauhaus": {
    fontFamily: theme.typography.fontFamilyBauhaus,
  },
}));
const Tbody = styled("tbody")(({ theme }) => ({}));
const Tr = styled("tr")(({ theme, color }) => ({
  color: color ? color : "inherit",
}));
const Td = styled("td")(({ theme }) => ({
  "&.vab": {
    verticalAlign: "bottom",
  },
}));
const Img = styled("img")(({ theme }) => ({}));
const Link = styled("a")(({ theme }) => ({}));
const Strong = styled("strong")(({ theme }) => ({}));
const Card = styled("div")(({ theme, color, minHeight }) => ({
  color: color ? color : "inherit",
  "&.card": {
    border: "1px solid rgba(62, 195, 236, 0.6)",
    padding: "10px 25px",
    minHeight: minHeight ? minHeight : 126,
  },
  [theme.breakpoints.down("sm")]: {
    "&.card": {
      padding: "10px 16px",
    },
  },
}));
const Buttons = styled("div")(({ theme }) => ({
  textAlign: "right",
  maxWidth: 725,
  marginTop: 20,

  a: {
    height: 43,
    lineHeight: "43px",
    backgroundImage: "url(" + btnbg + ")",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    fontSize: 16,
    textAlign: "center",
    padding: "0 25px",
    display: "inline-block",
    fontFamily: theme.typography.fontFamilyBauhaus,
    marginLeft: 15,

    img: {
      marginRight: 15,
      verticalAlign: "middle",
    },
  },
}));

export default function PDF({ presta, goBack, type, setDetailPresta }) {
  const { user } = useSelector((state) => state.userAuth);
  let userData = user.user?.data ? user.user?.data : user.data;
  //console.log(presta.documents.find((obj) => obj.type.toLowerCase() === type));
  const [detailDocument, setDetailDocument] = useState(
    presta?.documents?.find((obj) => obj.type.toLowerCase() === type)
      ? presta?.documents?.find((obj) => obj.type.toLowerCase() === type)
      : null
  );

  const [showAddService, setShowAddService] = useState(false);
  const [total, setTotal] = useState(presta?.total ? presta?.total : 0);
  const [subServices, setSubServices] = useState(
    presta?.service?.length > 0 ? presta?.service : []
  );
  const [subServicesNew, setSubServicesNew] = useState([]);
  const [subServicesDelete, setSubServicesDelete] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTotal(
      [...subServices, ...subServicesNew].reduce(
        (acc, obj) => acc + obj.prix * obj.quantity,
        0
      )
    );
  }, [subServices, subServicesNew]);

  const ignorerDocument = () => {
    setLoading(true);
    let data = {
      type_prestation: "devis_ignorer",
    };

    PrestationService.editPrestation(presta?.id, data).then((response) => {
      console.log(response);
      setDetailPresta(response);
      setLoading(false);
      goBack();
    });
  };

  const validateDocument = () => {
    setLoading(true);
    let dataSubServices = [];

    subServicesDelete.map((subServiceDelete, index) => {
      PrestationService.removeServices(subServiceDelete).then((response) => {
        console.log(response);
      });
    });
    subServicesNew.map((subService, index) => {
      if (subService.service && subService.service.value !== 0) {
        dataSubServices.push({
          prestation: "/api/demandes/" + presta?.id,
          service: "/api/services/" + subService.service.value,
          quantity: subService.quantity,
          prix: subService.prix,
        });
      } else {
        dataSubServices.push({
          prestation: "/api/demandes/" + presta?.id,
          nom_service: subService.nom_service,
          quantity: subService.quantity,
          prix: subService.prix,
        });
      }
      if (subService.service && subService.service.value !== 0) {
        PrestationService.addServices({
          prestation: "/api/demandes/" + presta?.id,
          service: "/api/services/" + subService.service.value,
          quantity: subService.quantity,
          prix: subService.prix,
        }).then((response) => {
          //setLoading(false);
          //console.log(response);
        });
      } else {
        PrestationService.addServices({
          prestation: "/api/demandes/" + presta?.id,
          nom_service: subService.nom_service,
          quantity: subService.quantity,
          prix: subService.prix,
        }).then((response) => {
          //setLoading(false);
          console.log(response);
        });
      }
    });

    let dataDocument = {
      prestation: "/api/demandes/" + presta?.id,
      type: document_type_devis,
    };

    let data = {
      total_ht: total.toString(),
      total_ttc: checkedTva
        ? (total + total * 0.2).toString()
        : total.toString(),
      tva: checkedTva,
      type_prestation: "devis",
      /* service: dataSubServices, */
    };
    PrestationService.addDocument(dataDocument).then((response) => {
      //setLoading(false);
      //console.log(response);
    });
    /* PrestationService.addServices(dataSubServices).then((response) => {
      //setLoading(false);
      console.log(response);
    }); */

    PrestationService.editPrestation(presta?.id, data).then((response) => {
      setDetailPresta(response);
      setLoading(false);
      goBack();
    });
  };

  const removeService = (serviceRemoved, isNew) => {
    if (isNew) {
      let newSubServicesNew = subServicesNew.filter(
        (item) => item !== serviceRemoved
      );
      setSubServicesNew(newSubServicesNew);
    } else {
      let newSubServices = subServices.filter(
        (item) => item !== serviceRemoved
      );
      setSubServices(newSubServices);
      setSubServicesDelete([...subServicesDelete, ...[serviceRemoved]]);
    }
  };
  const [checkedTva, setCheckedTva] = useState(presta.tva ? true : false);
  const handleChange = (event) => {
    setCheckedTva(event.target.checked);
  };

  const [loadingDevis, setLoadingDevis] = useState(false);
  const [showDetail, setShowDetail] = useState(true);

  const downloadPdf = () => {
    setShowDetail(false);
    setLoadingDevis(true);
    const pdfOptions = {
      margin: 10,
      filename: "devis-" + detailDocument?.id + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    const element = document.getElementById("pdf-container"); // Replace 'pdf-container' with the ID of the container holding your HTML content

    setTimeout(() => {
      html2pdf(element, pdfOptions);
      setLoadingDevis(false);
      setTimeout(() => {
        setShowDetail(true);
      }, 1000);
    }, 1000);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box display={"flex"} alignItems="flex-start">
          <Box>
            <Box
              display={"flex"}
              justifyContent="space-between"
              marginBottom={"6px"}
            >
              <Box>
                <Button
                  startIcon={<KeyboardBackspaceOutlined />}
                  variant={"text"}
                  color={"primary"}
                  sx={{ fontWeight: 700, textTransform: "capitalize" }}
                  onClick={goBack}
                >
                  Retour
                </Button>
              </Box>
              <Box
                fontSize="16px"
                fontFamily={theme.typography.fontFamilyBauhaus}
              >
                <Button
                  startIcon={<Download />}
                  variant={"text"}
                  color={"primary"}
                  sx={{ fontWeight: 700, textTransform: "capitalize" }}
                  onClick={downloadPdf}
                >
                  {loadingDevis ? (
                    <CircularProgress size="25px" />
                  ) : (
                    "Télécharger"
                  )}
                </Button>
              </Box>
            </Box>
            <div>
              <div id="pdf-container">
                <PdfWrap className="pdf" style={StyleSheet.flatten(styles.pdf)}>
                  <Table className="head">
                    <Tbody>
                      <Tr>
                        <Td>
                          <Img src={logo} />
                        </Td>
                        <Td>
                          <Table className="infos">
                            <Tbody>
                              <Tr className="b fz18">
                                <Td align="right" className="paddingRight40">
                                  Devis N°
                                </Td>
                                <Td>
                                  D
                                  {detailDocument
                                    ? detailDocument.id
                                    : moment().format("DDMMYYYYHHmmss")}
                                </Td>
                              </Tr>
                              <Tr className="b fz18">
                                <Td align="right" className="paddingRight40">
                                  Date° :
                                </Td>
                                <Td>
                                  {detailDocument
                                    ? moment
                                        .parseZone(detailDocument.date)
                                        .format("DD/MM/YYYY")
                                    : moment().format("DD/MM/YYYY")}
                                </Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>

                  <Table className="details">
                    <Tbody>
                      <Tr>
                        <Td className="dt1">
                          <Table>
                            <Tbody>
                              <Tr>
                                <Td>Société</Td>
                                <Td>
                                  <Strong>Fibme</Strong>
                                </Td>
                              </Tr>
                              <Tr>
                                <Td>Siège social</Td>
                                <Td>18 Place Marcel Rebuffat</Td>
                              </Tr>
                              <Tr>
                                <Td>Code Postal</Td>
                                <Td>91140 </Td>
                              </Tr>
                              <Tr>
                                <Td>Ville</Td>
                                <Td>VILLEJUST </Td>
                              </Tr>
                              <Tr>
                                <Td>Téléphone</Td>
                                <Td>01 64 47 52 49</Td>
                              </Tr>
                              <Tr>
                                <Td>E-mail</Td>
                                <Td>contact@fibme.com</Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Td>
                        <Td className="dt2">
                          <Table>
                            <Tbody>
                              <Tr>
                                <Td width="50%">Client</Td>
                                <Td>
                                  {presta?.client ? (
                                    <>
                                      <Strong>{`${presta?.client?.prenom} ${presta?.client?.nom}`}</Strong>
                                    </>
                                  ) : (
                                    <TextInput
                                      type="text"
                                      style={{
                                        height: 25,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        border: "1px solid #fff",
                                      }}
                                      placeholder="Saisissez le nom ici"
                                    />
                                  )}
                                </Td>
                              </Tr>
                              <Tr>
                                <Td>Adresse</Td>
                                <Td>{presta?.client?.adresse}</Td>
                              </Tr>
                              <Tr>
                                <Td>Code Postal</Td>
                                <Td>{presta?.client?.code_postal} </Td>
                              </Tr>
                              <Tr>
                                <Td>Ville</Td>
                                <Td>{presta?.client?.ville} </Td>
                              </Tr>
                              <Tr>
                                <Td>Téléphone</Td>
                                <Td>{presta?.client?.telephone}</Td>
                              </Tr>
                              <Tr>
                                <Td>E-mail</Td>
                                <Td>{presta?.client?.email}</Td>
                              </Tr>
                            </Tbody>
                          </Table>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>

                  <Typography variant="h2" className="h2">
                    Nature du problème
                  </Typography>
                  <Card className="card" color={theme.palette.secondary.main}>
                    {presta?.client?.problemes.map((x) => `${x}`)}
                  </Card>

                  <Table className="prodlist">
                    <Tbody>
                      <Tr>
                        <Td width="90">Réf</Td>
                        <Td>Déscriptions</Td>
                        <Td width="70">Qté</Td>
                        <Td width="90">PU HT</Td>
                        <Td width="120">Montant HT</Td>
                        {showDetail &&
                          (userData?.role == "ROLE_SUPER_ADMIN" ||
                            userData?.role == "ROLE_ADMIN") && (
                            <Td width=""></Td>
                          )}
                      </Tr>

                      {subServices.map((subService, index) => {
                        return (
                          <Tr color={theme.palette.secondary.main}>
                            <Td className="td b fz18 ffbauhaus">
                              {subService?.service &&
                              subService?.service?.id !== 0
                                ? "#" + subService?.service?.id
                                : "--"}
                            </Td>
                            <Td>
                              <Box className="tgl" data-label-sm="DIAGNOSTIC">
                                <Box>
                                  {subService?.service &&
                                  subService?.service?.id !== 0
                                    ? subService?.service?.libelle
                                    : subService?.nom_service}
                                  <br />
                                  {subService?.service &&
                                  subService?.service?.id !== 0
                                    ? subService?.service?.description
                                    : ""}
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Strong>{subService?.quantity}</Strong>
                            </Td>
                            <Td>
                              <Strong>{subService?.prix}</Strong>
                            </Td>
                            <Td>
                              <Strong>
                                {subService.quantity * subService.prix}
                              </Strong>
                            </Td>
                            {showDetail &&
                              (userData?.role == "ROLE_SUPER_ADMIN" ||
                                userData?.role == "ROLE_ADMIN") && (
                                <Td>
                                  <Box
                                    paddingTop={"5px"}
                                    textAlign={"right"}
                                    color={theme.palette.secondary.main}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <DeleteOutline
                                      onClick={() =>
                                        removeService(subService, false)
                                      }
                                    />
                                  </Box>
                                </Td>
                              )}
                          </Tr>
                        );
                      })}
                      {subServicesNew.map((subService, index) => {
                        return (
                          <Tr color={theme.palette.secondary.main}>
                            <Td className="td b fz18 ffbauhaus">
                              {subService?.service &&
                              subService?.service?.id !== 0
                                ? "#" + subService?.service?.id
                                : "--"}
                            </Td>
                            <Td>
                              <Box className="tgl" data-label-sm="DIAGNOSTIC">
                                <Box>
                                  {subService?.service &&
                                  subService?.service?.id !== 0
                                    ? subService?.service?.libelle
                                    : subService?.nom_service}
                                  <br />
                                  {subService?.service &&
                                  subService?.service?.id !== 0
                                    ? subService?.service?.description
                                    : ""}
                                </Box>
                              </Box>
                            </Td>
                            <Td>
                              <Strong>{subService?.quantity}</Strong>
                            </Td>
                            <Td>
                              <Strong>{subService?.prix}</Strong>
                            </Td>
                            <Td>
                              <Strong>
                                {subService.quantity * subService.prix}
                              </Strong>
                            </Td>
                            {showDetail &&
                              (userData?.role == "ROLE_SUPER_ADMIN" ||
                                userData?.role == "ROLE_ADMIN") && (
                                <Td>
                                  <Box
                                    paddingTop={"5px"}
                                    textAlign={"right"}
                                    color={theme.palette.secondary.main}
                                    sx={{ cursor: "pointer" }}
                                  >
                                    <DeleteOutline
                                      onClick={() =>
                                        removeService(subService, true)
                                      }
                                    />
                                  </Box>
                                </Td>
                              )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                  {showDetail &&
                    (userData?.role == "ROLE_SUPER_ADMIN" ||
                      userData?.role == "ROLE_ADMIN") && (
                      <Box
                        paddingTop={"5px"}
                        textAlign={"right"}
                        color={theme.palette.secondary.main}
                        sx={{ cursor: "pointer" }}
                      >
                        <AddBoxIcon onClick={() => setShowAddService(true)} />
                      </Box>
                    )}

                  <Box className="paiment">
                    <Table>
                      <Tbody>
                        <Tr>
                          <Td>
                            <Box paddingRight={5}>
                              {/* <Box
                      textAlign={"right"}
                      fontFamily={theme.typography.fontFamilyBauhaus}
                      fontSize="16px"
                      paddingBottom={"5px"}
                    >
                      <BorderColorIcon /> Modifier
                    </Box> */}
                              <Card minHeight="auto" className="card">
                                <Table>
                                  <Tbody>
                                    <Tr>
                                      <Td>Validité devis:</Td>
                                      <Td>Notre devis est valable 3 mois</Td>
                                    </Tr>
                                    <Tr>
                                      <Td>Paiement :</Td>
                                      <Td>
                                        A date du diagnostic sur site sur
                                        présentation de la facture
                                      </Td>
                                    </Tr>
                                    <Tr>
                                      <Td>Règlement : </Td>
                                      <Td>Par carte bancaire</Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </Card>
                            </Box>
                          </Td>
                          <Td className="vab">
                            <Table>
                              <Tbody>
                                <Tr>
                                  <Td className="b fz18 ffbauhaus">
                                    Total HT{" "}
                                  </Td>
                                  <Td className="fz18">{total.toFixed(2)} €</Td>
                                </Tr>

                                {showDetail &&
                                  (userData?.role == "ROLE_SUPER_ADMIN" ||
                                    userData?.role == "ROLE_ADMIN") && (
                                    <Tr>
                                      <Td className="b fz18 ffbauhaus">
                                        Applique la TVA ?{" "}
                                      </Td>
                                      <Td className="fz18">
                                        {" "}
                                        <Checkbox
                                          checked={checkedTva}
                                          onChange={(event) =>
                                            handleChange(event)
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 28,
                                            },
                                          }}
                                        />
                                      </Td>
                                    </Tr>
                                  )}

                                {checkedTva && (
                                  <Tr>
                                    <Td className="b fz18 ffbauhaus">
                                      TVA (20 %){" "}
                                    </Td>
                                    <Td className="fz18">
                                      {(total * 0.2).toFixed(2)} €
                                    </Td>
                                  </Tr>
                                )}
                                <Tr color={theme.palette.secondary.main}>
                                  <Td className="b fz18 ffbauhaus">
                                    <Box marginTop={"15px"}>Total TTC</Box>
                                  </Td>
                                  <Td className="fz18 b">
                                    <Box marginTop={"15px"}>
                                      {checkedTva
                                        ? (total + total * 0.2).toFixed(2)
                                        : total.toFixed(2)}{" "}
                                      €
                                    </Box>
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>

                  <Table className="signature">
                    <Tbody>
                      <Tr>
                        <Td className="fz18">
                          Signé le{" "}
                          {detailDocument
                            ? moment
                                .parseZone(detailDocument.date)
                                .format("DD/MM/YYYY")
                            : moment().format("DD/MM/YYYY")}
                        </Td>
                        {/* <Td width="33.33%">
                      <Box
                        marginRight={"10px"}
                        border={"1px dashed #fff"}
                        padding="10px"
                      >
                        <Img
                          style={{ filter: "invert(1) grayscale(1)" }}
                          src={signature}
                        />
                      </Box>
                    </Td>
                    <Td width="33.33%">
                      <Strong className="fz18">Craig Korsgaard</Strong>
                      <Box>Chef d’affaire</Box>
                    </Td> */}
                      </Tr>
                    </Tbody>
                  </Table>

                  <Box className="textBfrBtns">
                    <Box className="fz18">
                      Devis à nos retourner par mail daté et signé par le CLIENT
                      précédé de la mention “ Bon pour Accord”
                    </Box>
                  </Box>
                  {detailDocument &&
                    detailDocument.signature_client === "signed" && (
                      <Box className="_btssp disabled">
                        <Box>
                          <Link className="btn style1">{`${presta?.client?.prenom} ${presta?.client?.nom}`}</Link>
                          <Link className="btn">
                            Signé le{" "}
                            {detailDocument.date_signature_client
                              ? moment
                                  .parseZone(
                                    detailDocument.date_signature_client
                                  )
                                  .format("DD/MM/YYYY HH:mm")
                              : "---"}
                          </Link>
                        </Box>
                        {/* <Box>
                      <Box className="_blcsntr"></Box>
                    </Box> */}
                      </Box>
                    )}
                  <Box className="txt" color={theme.palette.secondary.main}>
                    Règlement comptant sans escompte. Le non respect du délai de
                    paiement expose le client, après mise en demeure, à des
                    pénalité de retard ainsi qu’à une indemnité forfaitaire pour
                    frais de recouvrement de 40,00€
                  </Box>
                  <Box className="ftr">
                    SASU au capital de 30 000,00 € - RCS EVRY 901 827 931 - TVA
                    intracommunautaire FR 16804898500 - APE 6190Z
                  </Box>
                </PdfWrap>
              </div>
            </div>
            {loading ? (
              <CircularProgress />
            ) : (
              (!detailDocument ||
                (detailDocument && detailDocument.status !== "signed")) && (
                <Buttons>
                  {!detailDocument && (
                    <ValidationButton icon handleClick={ignorerDocument}>
                      Ignorer
                    </ValidationButton>
                  )}
                  <ValidationButton icon handleClick={goBack}>
                    Annuler
                  </ValidationButton>
                  <ValidationButton handleClick={validateDocument}>
                    Valider
                  </ValidationButton>
                </Buttons>
              )
            )}
          </Box>
        </Box>
      </ThemeProvider>
      <AddNewServiceForm
        dialog={showAddService}
        onClose={() => setShowAddService(false)}
        subServicesNew={subServicesNew}
        setSubServicesNew={setSubServicesNew}
      />
    </>
  );
}
