import { styled } from "@mui/system";
import theme from "../../assets/theme";

const Img = styled('img')(({ theme }) => ({
  
}));
const Icon = styled('div')(({ theme }) => ({
    minHeight: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
}));

const Window = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
}));
const WinItem = styled('div')(({ theme, color, count }) => ({
    width: 'calc(50% - 20px)',
    padding: 40,
    margin: '10px 10px',
    backgroundImage: 'linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)',
    border: '1px solid #42C7F1',
    backdropFilter: 'blur(10px)',
    textAlign: 'center',
  
    '&:before': count ? {
        content: '"' + count + '"',
        position: 'absolute',
        right: 12,
        top: 12,
        width: 31,
        height: 31,
        borderRadius: 31,
        border: '1px solid ' + theme.palette.secondary.main,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '34px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: theme.palette.secondary.main,

    } : false,
    '.css-text-901oao, .css-901oao': {
      fontFamily: 'Brandon Grotesque',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: 24,
      lineHeight: '34px',
      textAlign: 'center',
      color: color ? color : '#002C3A',
      display: 'block'
    },
    [theme.breakpoints.down('sm')] : {
      width: '100%',
      margin: '10px 0',
    }
}));

export {Window, WinItem, Img, Icon};