import axios from "axios";
import authHeader from "./authHeader";
import { base_url_api } from "../../config/keys";

const listServices = async () => {
  try {
    const response = await axios.get(`${base_url_api}/services`, {
      headers: authHeader(),
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

const addService = async (data) => {
  try {
    const response = await axios.post(`${base_url_api}/services`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

const editService = async (id, data) => {
  try {
    const response = await axios.put(
      `${base_url_api}/services/${id}`,
      {
        ...data,
      },
      { headers: authHeader() }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

// const removeAdmin = async (id) => {
//   try {
//     const response = await axios.delete(`${base_url_api}/users/${id}`, {
//       headers: authHeader(),
//     });

//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

// const detailAdmin = async (id) => {
//   try {
//     const response = await axios.get(`${base_url_api}/users/${id}`, {
//       headers: authHeader(),
//     });

//     return response.data;
//   } catch (error) {
//     return error;
//   }
// };

const ServicesService = {
  addService,
  editService,
  // removeAdmin,
  // detailAdmin,
  // listActivities,
  listServices,
};

export default ServicesService;
