import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import logo from "../../../assets/images/logos/light_logo_lg.png";
import { styled, ThemeProvider } from "@mui/system";
import theme from "../../../assets/theme";
import styles from "../../../assets/css/style.js";
import btnbg from "../../../assets/images/btnbg.png";
import { StyleSheet } from "react-native";
import { TextInput } from "react-native-web";
import {
  Add,
  DeleteOutline,
  KeyboardBackspaceOutlined,
} from "@mui/icons-material";
import {
  document_type_devis,
  document_type_fiche_intervention,
} from "../../../config/keys.js";
import moment from "moment";
import PrestationService from "../../../store/apis/PrestationApi.js";
import * as yup from "yup";
import partnersService from "../../../store/apis/partnersApis.js";
import { Download } from "@mui/icons-material";
import html2pdf from "html2pdf.js";
import ValidationButton from "../../../components/buttons/ValidationButton.js";
moment.locale("fr");

const PdfWrap = styled("div")(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: 390,
  fontSize: 14,
  lineHeight: "20px",
  color: "#fff",
  maxWidth: 725,
  border: "1px solid #03EBFF",
  backgroundColor: "rgba(0, 0, 0, .69)",
  padding: "20px 30px",

  ".paddingRight40": {
    paddingRight: 40,
  },
  ".infos": {
    maxWidth: "max-content",
    margin: "0 0 0 auto",
  },
  ".fz18": {
    fontSize: 18,
  },
  ".details": {
    margin: "30px 0 20px",

    ".dt1": {
      width: "56%",
    },
    ".dt2": {
      width: "44%",
    },
  },
  ".h2": {
    marginBottom: 10,
  },
  ".prodlist": {
    borderSpacing: 0,

    tr: {
      td: {
        padding: 6,

        "&:not(:nth-of-type(2))": {
          textAlign: "center",
        },
      },
      "&:first-of-type": {
        fontSize: 16,
        lineHeight: "23px",
      },
      "&:not(:first-of-type)": {
        border: "1px solid #03EBFF",
        td: {
          border: "1px solid #03EBFF",
          verticalAlign: "top",
        },
      },
    },
  },
  ".paiment": {
    margin: "0 -30px",
    padding: "10px 30px 30px",
    borderBottom: "1px solid #03EBFF",
  },
  ".signature": {
    padding: "10px 0",
    img: {
      display: "block",
      margin: "auto",
    },
  },
  ".textBfrBtns": {
    display: "flex",
    marginBottom: 20,
    "> div:first-of-type": {
      flex: 1,
    },
    "> div:last-child": {
      width: 250,
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "0 -20px",
      width: "auto",

      "> div:last-child": {
        display: "none",
      },
    },
  },
  "._btssp": {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",

    "> div:first-of-type": {
      width: 250,
    },
    "> div:last-child": {
      width: 250,
    },
    "._blcsntr": {
      minHeight: "120px",
      marginTop: "10px",
      display: "flex",
      border: "2px dashed #04ebff",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 18,
      lineHeight: "22px",
      fontFamily: theme.typography.fontFamilyBauhaus,
      color: "#04ebff",

      "&.op15": {
        opacity: 0.15,
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "10px -20px 0",
      width: "auto",

      ">div:last-child": {
        width: "100%",
      },
      "._blcsntr": {
        minHeight: 100,
        margin: "13px 0 0 0",
      },
    },
  },
  ".btn": {
    height: 44,
    fontSize: 18,
    width: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #03EBFF",

    "&.style1": {
      backgroundImage:
        "linear-gradient(360deg, rgba(62, 195, 236, 0.12) -36%, rgba(62, 195, 236, 0) 76%)",
      textTransform: "uppercase",
      borderWidth: 2,
      marginBottom: 10,
    },
  },
  ".txt": {
    fontSize: 12,
    lineHeight: "17px",
    margin: "20px 0",
  },
  ".ftr": {
    margin: "auto",
    maxWidth: 270,
    fontSize: 9,
    lineHeight: "13px",
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    ".fz18": {
      fontSize: 14,
    },
    ".head": {
      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        img: {
          width: 105,
        },
      },
    },
    ".infos": {
      margin: 0,
      marginTop: "15px",

      td: {
        textAlign: "left",

        "&:last-child": {
          textAlign: "center",
        },
      },
    },
    ".paiment": {
      padding: "10px 10px 10px",

      "> table > tbody > tr": {
        display: "flex",
        flexDirection: "column-reverse",

        "> td": {
          marginBottom: 15,

          "&:last-child": {
            maxWidth: 170,
            width: "100%",
            margin: "0 0 0 auto",
          },
          "table td:first-of-type": {
            whiteSpace: "nowrap",
            verticalAlign: "text-bottom",
          },
          "table td": {
            paddingTop: 4,
          },
        },
      },
    },
    ".details": {
      marginBottom: 5,

      "> tbody > tr": {
        display: "flex",
        flexDirection: "column",

        "> td": {
          marginBottom: 15,
        },
      },
      ".dt1, .dt2": {
        width: "100%",

        td: {
          width: "50%",
        },
      },
    },
    ".prodlist ": {
      margin: "15px -31px 0",
      width: "calc(100% + 62px)",

      tr: {
        "&:first-of-type": {
          fontSize: 12,
        },
      },
      ".tgl": {
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "&:before": {
          content: "attr(data-label-sm)",
          fontSize: 14,
          fontWeight: "bold",
          fontFamily: theme.typography.fontFamilyBauhaus,
          color: "#000",
        },
        "&:after": {
          content: '""',
          border: "6px solid transparent",
          borderTopColor: theme.palette.primary.main,
          borderBottom: "none",
          marginLeft: 6,
        },
        "> div": {
          position: "absolute",
          top: "100%",
          left: 0,
          width: 200,
          backgroundColor: "#fff",
          padding: 4,
          zIndex: 100,
          border: "1px solid #ccc",
          borderRadius: 4,
          opacity: 0,
          visibility: "hidden",
        },
        "&:hover": {
          "> div": {
            opacity: 1,
            visibility: "visible",
          },
        },
      },
    },
    ".signature": {
      margin: "0 -20px",
      width: "auto",

      "> tbody > tr": {
        display: "flex",
        flexWrap: "wrap",

        td: {
          width: "50%",
          textAlign: "center",

          "&:first-of-type": {
            width: "100%",
            marginBottom: 15,
            textAlign: "left",
          },
        },
      },
    },
    ".txt": {
      margin: "20px -20px",
    },
  },
}));
const Table = styled("table")(({ theme }) => ({
  width: "100%",
  ".b": {
    fontWeight: "bold",
  },
  ".ffbauhaus": {
    fontFamily: theme.typography.fontFamilyBauhaus,
  },
}));
const Tbody = styled("tbody")(({ theme }) => ({}));
const Tr = styled("tr")(({ theme, color }) => ({
  color: color ? color : "inherit",
}));
const Td = styled("td")(({ theme }) => ({}));
const Img = styled("img")(({ theme }) => ({}));
const Link = styled("a")(({ theme }) => ({}));
const Strong = styled("strong")(({ theme }) => ({}));
const Card = styled("div")(({ theme, color, minheight, borderColor }) => ({
  color: color ? color : "inherit",
  "&.card": {
    border: "1px solid rgba(62, 195, 236, 0.6)",
    borderColor: borderColor ? borderColor : "rgba(62, 195, 236, 0.6)",
    padding: "10px 25px",
    minHeight: minheight ? minheight : 126,
  },
  [theme.breakpoints.down("sm")]: {
    "&.card": {
      padding: "10px 16px",
    },
  },
}));
const customStyles = {
  option: (base) => ({
    ...base,
    color: "#000",
    fontSize: 18,
    backgroundColor: "transparent",
  }),

  singleValue: (base) => ({
    ...base,
    color: "#ddd",
    fontSize: 14,
  }),
  indicatorSeparator: (base) => ({
    display: "none",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "rgba(62, 195, 236, 0.02)",
    height: 42,
    fontSize: 14,
    padding: "1px 16px",
    border: state.isFocused
      ? "1px solid " + theme.palette.secondary.main
      : "1px solid " + theme.palette.secondary.main,
    color: "#ddd",
    borderRadius: 0,
  }),
  input: (base) => ({
    ...base,
    fontSize: 18,
    backgroundColor: "transparent",
    color: "#ddd",
  }),
};

const Buttons = styled("div")(({ theme }) => ({
  textAlign: "right",
  maxWidth: 725,
  marginTop: 20,

  a: {
    height: 43,
    lineHeight: "43px",
    backgroundImage: "url(" + btnbg + ")",
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
    fontSize: 16,
    textAlign: "center",
    padding: "0 25px",
    display: "inline-block",
    fontFamily: theme.typography.fontFamilyBauhaus,
    marginLeft: 15,

    img: {
      marginRight: 15,
      verticalAlign: "middle",
    },
  },
}));

const schema = yup
  .object({
    intervenant: yup.number().required("Sélectionnez un partenaire"),
  })
  .required();

export default function PDFFile({ presta, goBack, setDetailPresta }) {
  const [loading, setLoading] = useState(false);
  const [showImages, setShowImages] = useState(true);
  const [loadPartenaire, setLoadPartenaire] = useState(false);
  const [detailDevis, setDetailDevis] = useState(
    presta.documents.find(
      (obj) => obj.type.toLowerCase() === document_type_devis
    )
      ? presta.documents.find(
          (obj) => obj.type.toLowerCase() === document_type_devis
        )
      : null
  );
  const [detailDocument, setDetailDocument] = useState(
    presta.documents.find(
      (obj) => obj.type.toLowerCase() === document_type_fiche_intervention
    )
      ? presta.documents.find(
          (obj) => obj.type.toLowerCase() === document_type_fiche_intervention
        )
      : null
  );

  const [subServices, setSubServices] = useState(
    presta?.service?.length > 0 ? presta?.service : []
  );

  const [date, setDate] = useState(moment.parseZone(presta?.date_prestation));
  const [duree, setDuree] = useState(presta?.duree_intervention);
  const [heureDebut, setHeureDebut] = useState(presta?.debut_intervention);
  const [heureFin, setHeureFin] = useState(presta?.fin_intervention);
  const [imageAfters, setImageAfters] = useState(presta?.image_afters);
  const [imageBefores, setImageBefores] = useState(presta?.image_befores);
  const [partenaires, setPartenaires] = useState({});

  const [errorDuree, setErrorDuree] = useState(false);
  const [errorDebut, setErrorDebut] = useState(false);
  const [errorFin, setErrorFin] = useState(false);

  useEffect(() => {
    setLoadPartenaire(true);
    const listPartenaires = [];
    partnersService
      .listPartners(1)
      .then((res) => {
        if (res) {
          for (let i = 0; i < res.length; i++) {
            const El = res[i];
            listPartenaires.push({
              value: El.id,
              label: El.prenom + " " + El.nom + " (" + El.id + ")",
            });
          }
          setPartenaires(listPartenaires);

          setTimeout(() => {
            setLoadPartenaire(false);
          }, 500);
        }
      })
      .catch((error) => {
        setLoadPartenaire(false);
        console.log(error);
      });
  }, []);

  const downloadPdf = () => {
    setShowImages(false);
    setLoading(true);
    const pdfOptions = {
      margin: 10,
      filename: "FicheIntervention-" + detailDocument?.id + ".pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    const element = document.getElementById("pdf-container"); // Replace 'pdf-container' with the ID of the container holding your HTML content

    setTimeout(() => {
      html2pdf(element, pdfOptions);
      setLoading(false);
      setTimeout(() => {
        setShowImages(true);
      }, 1000);
    }, 1000);
  };

  const [selectedFilesBefore, setSelectedFilesBefore] = useState([]);
  const [selectedFilesAfter, setSelectedFilesAfter] = useState([]);
  const [deletedFilesBefore, setDeletedFilesBefore] = useState([]);
  const [deletedFilesAfter, setDeletedFilesAfter] = useState([]);

  const handleFileChangeBefore = (event) => {
    setSelectedFilesBefore(event.target.files);
  };
  const handleFileChangeAfter = (event) => {
    setSelectedFilesAfter(event.target.files);
  };

  const handleDeleteFileBefore = (index) => {
    const newUploadedFiles = Array.from(selectedFilesBefore).filter(
      (_, i) => i !== index
    );
    setSelectedFilesBefore(newUploadedFiles);
  };

  const handleDeleteFileAfter = (index) => {
    const newUploadedFiles = Array.from(selectedFilesAfter).filter(
      (_, i) => i !== index
    );
    setSelectedFilesAfter(newUploadedFiles);
  };

  const handleDeleteOldFileBefore = (index, fileId) => {
    const newUploadedFiles = Array.from(imageBefores).filter(
      (_, i) => i !== index
    );
    deletedFilesBefore.push(fileId);
    setDeletedFilesBefore(deletedFilesBefore);
    setImageBefores(newUploadedFiles);
  };

  const handleDeleteOldFileAfter = (index, fileId) => {
    const newUploadedFiles = Array.from(imageAfters).filter(
      (_, i) => i !== index
    );
    deletedFilesAfter.push(fileId);
    setDeletedFilesAfter(deletedFilesAfter);
    setImageAfters(newUploadedFiles);
  };

  const validateDocument = () => {
    if (!duree) {
      setErrorDuree(true);
    } else {
      setErrorDuree(false);
    }
    if (!heureDebut) {
      setErrorDebut(true);
    } else {
      setErrorDebut(false);
    }
    if (!heureFin) {
      setErrorFin(true);
    } else {
      setErrorFin(false);
    }
    if (!duree || !heureDebut || !heureFin) return;

    let data = {
      date_prestation: date,
      duree_intervention: duree,
      debut_intervention: heureDebut,
      fin_intervention: heureFin,
    };

    setLoading(true);
    PrestationService.editPrestation(presta?.id, data).then((response) => {
      if (selectedFilesBefore.length > 0 || selectedFilesAfter.length > 0) {
        handleFileUpload(response);
      }
      if (deletedFilesBefore.length > 0 || deletedFilesAfter.length > 0) {
        handleFileDelete();
      }
      setTimeout(() => {
        setDetailPresta(response);
        setLoading(false);
        goBack();
      }, 500);
    });
  };

  const handleFileUpload = async () => {
    const formDataBefore = new FormData();
    formDataBefore.append("demande", presta?.id);
    for (let i = 0; i < selectedFilesBefore.length; i++) {
      formDataBefore.append("files[]", selectedFilesBefore[i]);
    }

    const formDataAfter = new FormData();
    formDataAfter.append("demande", presta?.id);
    for (let i = 0; i < selectedFilesAfter.length; i++) {
      formDataAfter.append("files[]", selectedFilesAfter[i]);
    }

    try {
      if (selectedFilesBefore.length > 0)
        PrestationService.addImagesBefore(formDataBefore).then((response) => {
          console.log(response);
        });
      if (selectedFilesAfter.length > 0)
        PrestationService.addImagesAfter(formDataAfter).then((response) => {
          console.log(response);
        });
    } catch (error) {
      console.error("Error uploading files:", error);
      alert("Failed to upload files");
    }
  };

  const handleFileDelete = async () => {
    try {
      if (deletedFilesBefore.length > 0) {
        for (let i = 0; i < deletedFilesBefore.length; i++) {
          PrestationService.deleteImagesBefore(deletedFilesBefore[i]).then(
            (response) => {
              console.log(response);
            }
          );
        }
      }
      if (deletedFilesAfter.length > 0) {
        for (let i = 0; i < deletedFilesAfter.length; i++) {
          PrestationService.deleteImagesAfter(deletedFilesAfter[i]).then(
            (response) => {
              console.log(response);
            }
          );
        }
      }
    } catch (error) {
      console.error("Error deleting files:", error);
      alert("Failed to delete files");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box display={"flex"} alignItems="flex-start">
        <Box>
          <Box
            display={"flex"}
            justifyContent="space-between"
            marginBottom={"6px"}
          >
            <Box>
              <Button
                startIcon={<KeyboardBackspaceOutlined />}
                variant={"text"}
                color={"primary"}
                sx={{ fontWeight: 700, textTransform: "capitalize" }}
                onClick={goBack}
              >
                Retour
              </Button>
            </Box>
            <Box
              fontSize="16px"
              fontFamily={theme.typography.fontFamilyBauhaus}
            >
              <Button
                startIcon={<Download />}
                variant={"text"}
                color={"primary"}
                sx={{ fontWeight: 700, textTransform: "capitalize" }}
                onClick={downloadPdf}
              >
                {loading ? <CircularProgress size="25px" /> : "Télécharger"}
              </Button>
            </Box>
          </Box>
          <div>
            <div id="pdf-container">
              <PdfWrap className="pdf" style={StyleSheet.flatten(styles.pdf)}>
                <Table className="head">
                  <Tbody>
                    <Tr>
                      <Td>
                        <Img src={logo} />
                      </Td>
                      <Td>
                        <Table className="infos">
                          <Tbody>
                            <Tr className="b fz18">
                              <Td align="right" className="paddingRight40">
                                Fiche d’intervention N°
                              </Td>
                              <Td>
                                {detailDocument
                                  ? detailDocument.id
                                  : moment().format("DDMMYYYYHHmmss")}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>

                <Table className="details">
                  <Tbody>
                    <Tr>
                      <Td className="dt1">
                        <Table>
                          <Tbody>
                            <Tr>
                              <Td>
                                <Strong className="fz18">
                                  Référence{" "}
                                  {presta?.reference
                                    ? presta?.reference
                                    : presta?.id}
                                </Strong>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>
                                <Strong className="fz18">
                                  Devis N°{" "}
                                  {detailDevis
                                    ? detailDevis.id
                                    : moment().format("DDMMYYYYHHmmss")}
                                </Strong>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>
                                <Strong>Adresse du lieu concerné</Strong>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td>{presta?.client?.adresse}</Td>
                            </Tr>
                            <Tr>
                              <Td>{presta?.client?.code_postal}</Td>
                            </Tr>
                            <Tr>
                              <Td>{presta?.client?.ville}</Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Td>
                      <Td className="dt2">
                        <Table>
                          <Tbody>
                            <Tr>
                              <Td align="right" width="50%">
                                <Strong>Date</Strong>
                              </Td>
                              <Td>
                                <Strong>
                                  {!showImages ? (
                                    <>{date.format("DD/MM/YYYY")}</>
                                  ) : (
                                    <TextInput
                                      type="text"
                                      style={{
                                        height: 25,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        border: "1px solid #fff",
                                        textAlign: "center",
                                        color: "#fff",
                                        placeholderTextColor:
                                          theme.palette.secondary.main,
                                      }}
                                      value={date.format("DD/MM/YYYY")}
                                      placeholder="--"
                                      disabled
                                    />
                                  )}
                                </Strong>
                              </Td>
                            </Tr>
                            <Tr>
                              <Td align="right">
                                <Strong>Durée</Strong>
                              </Td>
                              <Td>
                                {!showImages ? (
                                  <Strong>{duree}</Strong>
                                ) : (
                                  <TextInput
                                    type="number"
                                    keyboardType="numeric"
                                    style={{
                                      height: 25,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      border:
                                        "1px solid " +
                                        theme.palette.secondary.main,
                                      textAlign: "center",
                                      color: "#000",
                                      background: "#fff",
                                      borderColor: errorDuree
                                        ? "red"
                                        : theme.palette.secondary.main,
                                      placeholderTextColor:
                                        theme.palette.secondary.main,
                                    }}
                                    onChange={(e) => {
                                      setDuree(e.target.value);
                                    }}
                                    value={duree}
                                  />
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td align="right">
                                <Strong>Début</Strong>
                              </Td>
                              <Td>
                                {!showImages ? (
                                  <Strong>{heureDebut}</Strong>
                                ) : (
                                  <input
                                    type="time"
                                    name="time-debut"
                                    id="time-debut"
                                    lang="fr_FR"
                                    locale="fr_FR"
                                    style={{
                                      height: 25,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      border:
                                        "1px solid " +
                                        theme.palette.secondary.main,
                                      textAlign: "center",
                                      color: "#000",
                                      width: "100%",
                                      borderColor: errorDebut
                                        ? "red"
                                        : theme.palette.secondary.main,
                                      placeholderTextColor:
                                        theme.palette.secondary.main,
                                    }}
                                    onChange={(e) => {
                                      setHeureDebut(e.target.value);
                                    }}
                                    value={heureDebut}
                                  />
                                )}
                              </Td>
                            </Tr>
                            <Tr>
                              <Td align="right">
                                <Strong>Fin</Strong>
                              </Td>
                              <Td>
                                {!showImages ? (
                                  <Strong>{heureFin}</Strong>
                                ) : (
                                  <input
                                    type="time"
                                    name="time-fin"
                                    id="time-fin"
                                    lang="fr_FR"
                                    locale="fr_FR"
                                    style={{
                                      height: 25,
                                      paddingLeft: 10,
                                      paddingRight: 10,
                                      border:
                                        "1px solid " +
                                        theme.palette.secondary.main,
                                      textAlign: "center",
                                      color: "#000",
                                      width: "100%",
                                      borderColor: errorFin
                                        ? "red"
                                        : theme.palette.secondary.main,
                                      placeholderTextColor:
                                        theme.palette.secondary.main,
                                    }}
                                    onChange={(e) => {
                                      setHeureFin(e.target.value);
                                    }}
                                    value={heureFin}
                                  />
                                )}
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>

                <Box
                  display={"flex"}
                  flexWrap={"wrap"}
                  justifyContent="space-between"
                >
                  <Box width={"calc(50% - 5px)"}>
                    <Typography variant="h2" className="h2">
                      Client
                    </Typography>
                    <Card className="card" minheight="42px">
                      {`${presta?.client?.prenom} ${presta?.client?.nom}`}
                    </Card>
                  </Box>
                  <Box width={"calc(50% - 5px)"}>
                    <Typography variant="h2" className="h2">
                      Intervenant(s)
                    </Typography>
                    <Box>
                      <Card className="card" minheight="42px">
                        {`${presta?.prestataire?.prenom} ${presta?.prestataire?.nom}`}
                      </Card>
                      {/* {loadPartenaire ? (
                    <CircularProgress />
                  ) : (
                    <Controller
                      name={"intervenant"}
                      className="card"
                      minheight="42px"
                      borderColor={theme.palette.secondary.main}
                      color={theme.palette.secondary.main}
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          value={partner}
                          styles={customStyles}
                          placeholder={"Sélectionnez le partenaire"}
                          options={partenaires}
                          onChange={handleChangePartenaire}
                        />
                      )}
                    />
                  )} */}
                    </Box>
                  </Box>
                </Box>
                <Typography variant="h2" className="h2" marginTop={"30px"}>
                  Nature du problème
                </Typography>
                <Card className="card" minheight="auto">
                  {presta?.client?.problemes.map((x) => `${x}`)}
                </Card>
                <Typography variant="h2" className="h2" marginTop={"30px"}>
                  Détail
                </Typography>
                <Card className="card" minheight="auto">
                  {presta?.detail}
                </Card>

                <Table className="prodlist">
                  <Tbody>
                    <Tr>
                      <Td width="90">Réf</Td>
                      <Td>Désignation</Td>
                      <Td width="70">Qté</Td>
                    </Tr>
                    {subServices.map((subService, index) => {
                      return (
                        <Tr color={theme.palette.secondary.main}>
                          <Td className="td b fz18 ffbauhaus">
                            {subService?.service &&
                            subService?.service?.id !== 0
                              ? "#" + subService?.service?.id
                              : "--"}
                          </Td>
                          <Td>
                            <Box className="tgl" data-label-sm="DIAGNOSTIC">
                              <Box>
                                {subService?.service &&
                                subService?.service?.id !== 0
                                  ? subService?.service?.libelle
                                  : subService?.nom_service}
                                <br />
                                {subService?.service &&
                                subService?.service?.id !== 0
                                  ? subService?.service?.description
                                  : ""}
                              </Box>
                            </Box>
                          </Td>
                          <Td>
                            <Strong>{subService?.quantity}</Strong>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>

                {showImages && (
                  <>
                    <Box
                      fontFamily={theme.typography.fontFamilyBauhaus}
                      display={"flex"}
                      marginTop="50px"
                    >
                      <Box
                        flex="1"
                        display={"flex"}
                        alignItems="start"
                        justifyContent={"center"}
                        cursor={"pointer"}
                      >
                        <Box
                          color={"#F2E208"}
                          width={"100%"}
                          textAlign={"center"}
                        >
                          {detailDocument &&
                          detailDocument.status !== "signed" ? (
                            <label
                              htmlFor="file-input-after"
                              className="custom-file-upload"
                            >
                              Ajouter des photos
                              <br />
                              avant la réalisation
                            </label>
                          ) : (
                            <label
                              htmlFor="file-input-after"
                              className="custom-file-upload"
                            >
                              Photos avant la réalisation
                            </label>
                          )}
                        </Box>
                        {detailDocument &&
                          detailDocument.status !== "signed" && (
                            <Box
                              fontFamily={theme.typography.fontFamily}
                              flex="1"
                            >
                              <Box
                                color={"#F2E208"}
                                sx={{
                                  border: "1px solid #F2E208",
                                  padding: "8px 15px",
                                  width: "68px",
                                  height: "51px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                htmlFor="file-input-after"
                              >
                                <input
                                  id="file-input-after"
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  onChange={handleFileChangeBefore}
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor="file-input-after"
                                  className="custom-file-upload"
                                >
                                  <Add
                                    fontSize="large"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                              </Box>
                            </Box>
                          )}
                      </Box>
                      <Box
                        flex="1"
                        display={"flex"}
                        alignItems="start"
                        justifyContent={"center"}
                        cursor={"pointer"}
                      >
                        <Box
                          color={"#F2E208"}
                          width={"100%"}
                          textAlign={"center"}
                        >
                          {detailDocument &&
                          detailDocument.status !== "signed" ? (
                            <label
                              htmlFor="file-input-after"
                              className="custom-file-upload"
                            >
                              Ajouter des photos
                              <br />
                              après la réalisation
                            </label>
                          ) : (
                            <label
                              htmlFor="file-input-after"
                              className="custom-file-upload"
                            >
                              Photos après la réalisation
                            </label>
                          )}
                        </Box>
                        {detailDocument &&
                          detailDocument.status !== "signed" && (
                            <Box
                              fontFamily={theme.typography.fontFamily}
                              flex="1"
                            >
                              <Box
                                color={"#F2E208"}
                                sx={{
                                  border: "1px solid #F2E208",
                                  padding: "8px 15px",
                                  width: "68px",
                                  height: "51px",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                                htmlFor="file-input-befor"
                              >
                                <input
                                  id="file-input-befor"
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  onChange={handleFileChangeAfter}
                                  style={{ display: "none" }}
                                />
                                <label
                                  htmlFor="file-input-befor"
                                  className="custom-file-upload"
                                >
                                  <Add
                                    fontSize="large"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </label>
                              </Box>
                            </Box>
                          )}
                      </Box>
                    </Box>
                    <Box
                      fontFamily={theme.typography.fontFamilyBauhaus}
                      display={"flex"}
                      marginTop="10px"
                    >
                      <Box
                        flex="1"
                        display={"flex"}
                        alignItems="start"
                        justifyContent={"center"}
                      >
                        <div className="image-preview-container">
                          {imageBefores &&
                            imageBefores.map((file, key) => {
                              return (
                                <div key={key} className="image-preview">
                                  <p>
                                    <a href={file.file} target="_blank">
                                      <img
                                        src={file.file}
                                        alt={file.file}
                                        width={"80px"}
                                        style={{
                                          verticalAlign: "middle",
                                          border: "1px solid #F2E208",
                                        }}
                                      />
                                    </a>
                                    <br />
                                    {detailDocument &&
                                      detailDocument.status !== "signed" && (
                                        <Button
                                          startIcon={<DeleteOutline />}
                                          variant={"text"}
                                          sx={{
                                            fontWeight: { xs: 700 },
                                            fontSize: { xs: 16 },
                                            fontFamily: { xs: "Bauhaus" },
                                            textTransform: "capitalize",
                                          }}
                                          color="inherit"
                                          onClick={() =>
                                            handleDeleteOldFileBefore(
                                              key,
                                              file.id
                                            )
                                          }
                                        >
                                          Supprimer
                                        </Button>
                                      )}
                                  </p>
                                </div>
                              );
                            })}
                          {selectedFilesBefore &&
                            Array.from(selectedFilesBefore).map((file, key) => {
                              return (
                                <div key={key} className="image-preview">
                                  <p>
                                    <a
                                      href={URL.createObjectURL(file)}
                                      target="_blank"
                                    >
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        width={"80px"}
                                        style={{
                                          verticalAlign: "middle",
                                          border: "1px solid #F2E208",
                                        }}
                                      />
                                    </a>
                                    <br />
                                    {detailDocument &&
                                      detailDocument.status !== "signed" && (
                                        <Button
                                          startIcon={<DeleteOutline />}
                                          variant={"text"}
                                          sx={{
                                            fontWeight: { xs: 700 },
                                            fontSize: { xs: 16 },
                                            fontFamily: { xs: "Bauhaus" },
                                            textTransform: "capitalize",
                                          }}
                                          color="inherit"
                                          onClick={() =>
                                            handleDeleteFileBefore(key)
                                          }
                                        >
                                          Supprimer
                                        </Button>
                                      )}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </Box>
                      <Box
                        flex="1"
                        display={"flex"}
                        alignItems="start"
                        justifyContent={"center"}
                      >
                        <div className="image-preview-container">
                          {imageAfters &&
                            imageAfters.map((file, key) => {
                              return (
                                <div key={key} className="image-preview">
                                  <p>
                                    <a href={file.file} target="_blank">
                                      <img
                                        src={file.file}
                                        alt={file.file}
                                        width={"80px"}
                                        style={{
                                          verticalAlign: "middle",
                                          border: "1px solid #F2E208",
                                        }}
                                      />
                                    </a>
                                    <br />
                                    {detailDocument &&
                                      detailDocument.status !== "signed" && (
                                        <Button
                                          startIcon={<DeleteOutline />}
                                          variant={"text"}
                                          sx={{
                                            fontWeight: { xs: 700 },
                                            fontSize: { xs: 16 },
                                            fontFamily: { xs: "Bauhaus" },
                                            textTransform: "capitalize",
                                          }}
                                          color="inherit"
                                          onClick={() =>
                                            handleDeleteOldFileAfter(
                                              key,
                                              file.id
                                            )
                                          }
                                        >
                                          Supprimer
                                        </Button>
                                      )}
                                  </p>
                                </div>
                              );
                            })}
                          {selectedFilesAfter &&
                            Array.from(selectedFilesAfter).map((file, key) => {
                              return (
                                <div key={key} className="image-preview">
                                  <p>
                                    <a
                                      href={URL.createObjectURL(file)}
                                      target="_blank"
                                    >
                                      <img
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        width={"80px"}
                                        style={{
                                          verticalAlign: "middle",
                                          border: "1px solid #F2E208",
                                        }}
                                      />
                                    </a>
                                    <br />
                                    {detailDocument &&
                                      detailDocument.status !== "signed" && (
                                        <Button
                                          startIcon={<DeleteOutline />}
                                          variant={"text"}
                                          sx={{
                                            fontWeight: { xs: 700 },
                                            fontSize: { xs: 16 },
                                            fontFamily: { xs: "Bauhaus" },
                                            textTransform: "capitalize",
                                          }}
                                          color="inherit"
                                          onClick={() =>
                                            handleDeleteFileAfter(key)
                                          }
                                        >
                                          Supprimer
                                        </Button>
                                      )}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  fontFamily={theme.typography.fontFamilyBauhaus}
                  display={"flex"}
                  marginTop="50px"
                >
                  <Box
                    flex="1"
                    display={"flex"}
                    alignItems="center"
                    marginRight={"15px"}
                  >
                    <Box marginRight={"10px"}>Fait à</Box>
                    <Box fontFamily={theme.typography.fontFamily} flex="1">
                      <Card minheight="auto" className="card">
                        {presta?.client?.adresse} {presta?.client?.code_postal},{" "}
                        {presta?.client?.ville}
                      </Card>
                    </Box>
                  </Box>
                  <Box
                    maxWidth="225px"
                    flex="1"
                    display={"flex"}
                    alignItems="center"
                  >
                    <Box marginRight={"10px"}>Le</Box>
                    <Box fontFamily={theme.typography.fontFamily} flex="1">
                      <Card minheight="auto" className="card">
                        {detailDocument
                          ? moment
                              .parseZone(detailDocument.date)
                              .format("DD/MM/YYYY")
                          : moment().format("DD/MM/YYYY")}
                      </Card>
                    </Box>
                  </Box>
                </Box>

                <Box className="_btssp">
                  <Box margin={1}>
                    <Box fontSize="16px">Signature du client</Box>
                    <Box className="_blcsntr">
                      {detailDocument.signature_client === "signed" ? (
                        <>
                          Signe le{" "}
                          {moment
                            .parseZone(detailDocument.date_signature_client)
                            .format("DD/MM/YYYY HH:mm")}
                        </>
                      ) : (
                        "Pas encore"
                      )}
                    </Box>
                  </Box>
                  <Box margin={1}>
                    <Box fontSize="16px">Signature de l’intervenant</Box>
                    <Box className="_blcsntr">
                      {detailDocument.signature_prestataire === "signed" ? (
                        <>
                          Signe le{" "}
                          {moment
                            .parseZone(
                              detailDocument.date_signature_prestataire
                            )
                            .format("DD/MM/YYYY HH:mm")}
                        </>
                      ) : (
                        "Pas encore"
                      )}
                    </Box>
                  </Box>
                </Box>
              </PdfWrap>
            </div>
          </div>
          {loading ? (
            <CircularProgress />
          ) : (
            detailDocument &&
            detailDocument.status !== "signed" && (
              <Buttons>
                <ValidationButton icon handleClick={goBack}>
                  Annuler
                </ValidationButton>
                <ValidationButton handleClick={validateDocument}>
                  Valider
                </ValidationButton>
              </Buttons>
            )
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
